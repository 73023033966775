@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/create-mijnverbouwpremie-release/frontoffice/src/theme/includes.scss";@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/create-mijnverbouwpremie-release/frontoffice/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
table.wrp-cc-roof-parts.vl-data-table {
  width: "100%";
  table-layout: fixed;

  .editor-component-in-compact-list {
    label {
      display: none;
    }
  }

  td,
  th {
    padding: $vl-spacing-xsmall;
  }

  td:first-of-type,
  th:first-of-type {
    padding-left: 0;
  }

  td:last-of-type,
  th:last-of-type {
    padding-right: 0;
  }

  thead th .top-label-for-trash {
    display: none;
  }

  tbody {
    tr {
      border-bottom: none;
    }

    tr.row-with-inputs .inline-errors {
      display: none;
    }

    tr.row-with-errors td {
      padding-bottom: $vl-spacing-xxsmall;
      padding-top: 0;

      .inline-errors .vl-form__error:first-of-type {
        margin-top: 0;
      }
    }

    tr.row-with-total {
      border-top: 1px solid $vl-border-color;

      td {
        vertical-align: middle;
      }

      .vl-form__group {
        padding: inherit;
      }

      td.show-help-and-label-in-compact-list-anyway {
        &.label {
          text-align: right;
        }

        label.vl-form__label {
          display: initial;
          margin-right: 0;
        }

        .inline-help-wrapper {
          display: initial;
        }

        .help-wrapper {
          display: initial;
        }

        .vl-form__error {
          margin-top: 0;
        }
      }

      .total-value {
        padding-right: 10px;
        .inline-errors {
          display: none;
        }
        .vl-form__annotation.read-only {
          margin-top: -3px;
        }
      }
    }

    td.editor-component-in-compact-list {
      .list-remove-compact {
        margin-top: 5px;
      }

      .editor-component {
        margin: 0;
      }

      button {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  .vl-form__group.vl-input-field--error {
    display: block;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    height: 3.5rem;
    line-height: 3.5rem;
  }

  .vl-popover__content {
    .vl-action-group {
      margin-top: 1.5rem;
    }
  }
}
