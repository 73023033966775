@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/create-mijnverbouwpremie-release/frontoffice/src/theme/includes.scss";@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/create-mijnverbouwpremie-release/frontoffice/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.communication-preference {
  .wrp-check-ebox-again {
    margin-top: 1rem;
  }

  // "Safest option" pill
  #editor-manierVanOpDeHoogteBrengen > label:nth-child(2) .vl-pill {
    margin-left: 15px;
  }
  // Align "Safest option" pill with label "eBox"
  #editor-manierVanOpDeHoogteBrengen
    > label:nth-child(2)
    > div
    > div.vl-radio__label {
    display: flex;
    align-items: center;
  }

  // Subfields of "hoe wenst op de hoogte gebracht te worden van uw dossier?" should align the text with the "other options" label
  // (the padding equals the radio button and spacing to the right)
  // Not too much spacing around subfields for "hoe wenst op de hoogte gebracht te worden van uw dossier?"
  #editor-manierVanOpDeHoogteBrengen
    > label:nth-child(2)
    > div.editor-component,
  #editor-manierVanOpDeHoogteBrengen
    > label:nth-child(3)
    > div:nth-child(2)
    > div.editor-component {
    margin-left: 2.4rem;

    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  // Subfields underneath "email address" and "post" should align the text with the "other options" label
  // (the padding equals the radio button and spacing to the right)
  #editor-manierVanOpDeHoogteBrengen-andereOptiesChoice-bevestigUwEmail,
  #editor-manierVanOpDeHoogteBrengen-andereOptiesChoice-emailAdres,
  #editor-manierVanOpDeHoogteBrengen-andereOptiesChoice
    > label:nth-child(3)
    > div:nth-child(2) {
    margin-left: 2.4rem;
  }

  // Not too much spacing around subfields for "email address" and "post" fields
  #editor-manierVanOpDeHoogteBrengen-andereOptiesChoice
    > label:nth-child(2)
    > div:nth-child(2)
    .editor-component,
  #editor-manierVanOpDeHoogteBrengen-andereOptiesChoice
    > label:nth-child(3)
    > div:nth-child(2)
    .editor-component {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  // Don't show label of the choice underneath "Other options"
  #editor-manierVanOpDeHoogteBrengen-andereOptiesChoice > div > label {
    // visually hidden, but still accessible
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  // Margin bottom underneath "Same as domicilie address checkbox"
  #editor-manierVanOpDeHoogteBrengen-andereOptiesChoice
    > label:nth-child(3)
    > div:nth-child(2)
    > div
    > div
    > div:nth-child(1) {
    margin-bottom: 1.5rem;
  }
}
