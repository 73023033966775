@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/create-mijnverbouwpremie-release/frontoffice/src/theme/includes.scss";@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/create-mijnverbouwpremie-release/frontoffice/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.wrp-form-summary {
  margin-bottom: 3rem;

  .editor-component {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .vl-region {
    padding: 0;
    padding-top: 4rem;
  }

  .vl-title.vl-title--h2 {
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  // hide help fields, required indications, notifications and warnings
  .vl-u-mark--warning,
  .inline-help-wrapper,
  .help-icon,
  .label-required,
  .notification,
  .form-warning {
    display: none;
  }

  // hide certain fields
  #editor-inloggen,
  #editor-gezinssamenstellingInfo,
  #editor-dummyPlaceholder,
  #editor-warmtepomp-doeHetZelfWerkenKomenNietMeerInAanmerking {
    display: none;
  }

  #editor-familyComposition > .input-component-wrapper {
    display: none;
  }

  table.attachments-table > tbody > tr {
    background: initial;
    border-bottom: initial;
  }
}
