$wrp-font-family: "wrp-icons" !default;
$wrp-font-path: "./assets/icons" !default;

$wrp-icon-file: "\e906";
$wrp-icon-binnenrenovatie: "\e900";
$wrp-icon-buitenschrijnwerk: "\e901";
$wrp-icon-dakrenovatie: "\e902";
$wrp-icon-gascondensatieketel: "\e903";
$wrp-icon-muurrenovatie: "\e904";
$wrp-icon-technische-installaties: "\e905";
$wrp-icon-vloerrenovatie: "\e907";
$wrp-icon-warmtepomp: "\e908";
$wrp-icon-warmtepompboiler: "\e909";
$wrp-icon-zonneboiler: "\e90a";
$wrp-icon-zonnepanelen: "\e90b";
