@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/create-mijnverbouwpremie-release/frontoffice/src/theme/includes.scss";@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/create-mijnverbouwpremie-release/frontoffice/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.attachments-table {
  @media (min-width: $vl-bp-small) {
    td {
      &:last-child {
        width: 35%;
      }
      .input-component {
        width: 100%;
      }
    }
  }
  .attachment-cell {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
