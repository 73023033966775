@font-face {
  font-family: "#{$wrp-font-family}";
  src: url("#{$wrp-font-path}/#{$wrp-font-family}.ttf?dro446")
      format("truetype"),
    url("#{$wrp-font-path}/#{$wrp-font-family}.woff?dro446") format("woff"),
    url("#{$wrp-font-path}/#{$wrp-font-family}.svg?dro446##{$wrp-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="wrp-icon-"],
[class*=" wrp-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$wrp-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  text-decoration: none;
  display: inline-block;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrp-icon-file {
  &:before {
    content: $wrp-icon-file;
  }
}
.wrp-icon-binnenrenovatie {
  &:before {
    content: $wrp-icon-binnenrenovatie;
  }
}
.wrp-icon-buitenschrijnwerk {
  &:before {
    content: $wrp-icon-buitenschrijnwerk;
  }
}
.wrp-icon-dakrenovatie {
  &:before {
    content: $wrp-icon-dakrenovatie;
  }
}
.wrp-icon-gascondensatieketel {
  &:before {
    content: $wrp-icon-gascondensatieketel;
  }
}
.wrp-icon-muurrenovatie {
  &:before {
    content: $wrp-icon-muurrenovatie;
  }
}
.wrp-icon-technische-installaties {
  &:before {
    content: $wrp-icon-technische-installaties;
  }
}
.wrp-icon-vloerrenovatie {
  &:before {
    content: $wrp-icon-vloerrenovatie;
  }
}
.wrp-icon-warmtepomp {
  &:before {
    content: $wrp-icon-warmtepomp;
  }
}
.wrp-icon-warmtepompboiler {
  &:before {
    content: $wrp-icon-warmtepompboiler;
  }
}
.wrp-icon-zonneboiler {
  &:before {
    content: $wrp-icon-zonneboiler;
  }
}
.wrp-icon-zonnepanelen {
  &:before {
    content: $wrp-icon-zonnepanelen;
  }
}
